import React from "react"
import BlockWrapper from "../technical/BlockWrapper"
import GridWrapper from "../technical/GridWrapper"
import SliderWrapper from "../technical/SliderWrapper"

import Card from "../molecules/Card"
import shuffleArray from "../../utils/shuffleArray"

const CardsBlock = ({ block, forceLoadImages = false }) => {
  let view = block.view?.length > 0 ? block.view : "grid"
  let showSlides = parseInt(
    block.show_slides && block.show_slides !== "" ? block.show_slides : 4
  )

  let shuffle = block.shuffle?.length > 0 ? block.shuffle : false

  if (shuffle === true) {
    shuffleArray(block.card_items)
  }

  if (view === "grid") {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <GridWrapper showSlides={showSlides}>
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div
                className={`block w-full mt-8 sm:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <Card item={item} forceLoadImages={forceLoadImages} />
              </div>
            ))}
        </GridWrapper>
      </BlockWrapper>
    )
  } else {
    return (
      <BlockWrapper block={block} showHeadline="true">
        <SliderWrapper showSlides={showSlides} _uid={block._uid}>
          {block.card_items?.length > 0 &&
            block.card_items.map((item, index) => (
              <div
                className={`block w-full mt-8 sm:w-1/2 lg:w-1/${showSlides} px-grid`}
                key={index}
              >
                <Card item={item} forceLoadImages={forceLoadImages} />
              </div>
            ))}
        </SliderWrapper>
      </BlockWrapper>
    )
  }
}

export default CardsBlock
