import React from "react"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

const Card = ({ item, forceLoadImages = false }) => {
  return (
    <Link className="flex-1 block w-full h-full group" link={item.link}>
      {item.image?.filename?.length > 0 || item.image?.length > 0 ? (
        <Image
          className="block w-full h-auto pb-4"
          image={item.image}
          fitIn={item.image_fitin}
          aspectRatio="4by3"
          forceLoad={forceLoadImages}
        />
      ) : (
        <Image
          className="block w-full h-auto pb-4"
          image={{
            filename:
              "https://a.storyblok.com/f/125728/1000x1000/5a8c542d51/ro_placeholder.jpg",
          }}
          fitIn={item.image_fitin}
          aspectRatio="4by3"
        />
      )}
      <div className="">
        {item.kicker?.length > 0 && (
          <span className="block mb-1 text-sm hyphens-auto">{item.kicker}</span>
        )}
        {item.headline?.length > 0 && (
          <span className="block font-bold text-black group-hover:text-primary hyphens-auto">
            {item.headline}
          </span>
        )}
        <Richtext text={item.text} />
        {item.link_text?.length > 0 && (
          <span className="inline-block mt-4 font-normal text-primary group-hover:text-primary-hover">
            {item.link_text}
          </span>
        )}
      </div>
    </Link>
  )
}

export default Card
